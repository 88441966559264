.img-bg #offline {
  text-align: center;
  font-weight: 600;
}
.img-bg .Container {
  background-color: azure !important;
}
.img-bg .card {
  border: 4px solid #6e4c43;
  padding: 30px 30px;
  border-width: 4px 0px;
  width: fit-content;
}
/* .Parent {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100vh;
} */
.img-bg .btn-success {
  color: #fff;
  background-color: #3e3935;
  border-color: #3e3935;
}
.img-bg .btn-success:hover {
  color: #fff;
  background-color: #6e4c43;
  border-color: #6e4c43;
}
.img-bg ::marker {
  color: #6e4c43;
}
.img-bg li#des {
  color: #6e4c43;
}
.img-bg span#siteName {
  font-weight: 600;
}
/* span#cont {
color: #6e4c43;
} */
.img-bg h6.text-left.mt-4.font-weight-bold.mb-0 {
  font-weight: 700;
}
